import { saveAs } from 'file-saver'

export default {
  methods: {
    downloadCSV(items, fileName = 'file', suffix = '.csv') {
      const blob = new Blob([items], { type: 'data:text/csv;charset=utf-8' })
      saveAs(blob, `${fileName}${suffix}`)
    },
    downloadXLSX(data, fileName = 'file', suffix = '.xlsx') {
      // NOTE: In api call set responseType: 'blob'
      saveAs(
        data,
        `${fileName}${suffix}`,
      )
    },
  },
}
