<template>
  <b-card no-body>
    <b-card-header>
      <h4>Purchase Items stock Report</h4>
    </b-card-header>
    <b-card-body>
      <b-alert
        variant="danger"
        :show="errorMessage ? true : false"
      >
        <div class="alert-body">
          <p>
            {{ errorMessage }}
          </p>
        </div>
      </b-alert>
      <b-form @submit.prevent="onSubmit">
        <b-row>
          <b-col
            md="6"
            lg="4"
            xl="3"
          >
            <b-form-group
              label="Batch stock file"
              label-for="stock-file"
            >
              <b-form-file
                id="stock-file"
                v-model="stockFile"
                placeholder="Batch stock file"
                accept=".xlsx"
              />
              <p class="field-remark">
                Stock data with only available stock with batch detail
              </p>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            md="6"
            lg="4"
            xl="3"
          >
            <b-form-group
              label="Invoice files"
              label-for="invoice-files"
            >
              <b-form-file
                id="invoice-files"
                v-model="invoiceFiles"
                placeholder="Invoice files"
                multiple
                accept=".csv"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-form-checkbox v-model="skipSavingVerifiedRecords">
          Skip adding verified stock report
        </b-form-checkbox>
        <b-button
          variant="primary"
          :disabled="submitting"
          type="submit"
          class="mt-2"
        >
          Submit
          <b-spinner
            v-if="submitting"
            small
            label="Small Spinner"
          />
        </b-button>
      </b-form>
    </b-card-body>
  </b-card>
</template>

<script>
import axios from 'axios'
import downloadFileMixin from '@/mixins/downloadFileMixin'
import {
  BCard,
  BCardHeader,
  BCardBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormFile,
  BButton,
  BAlert,
  BSpinner,
  VBTooltip,
  BFormCheckbox,

} from 'bootstrap-vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormFile,
    BButton,
    BAlert,
    BSpinner,
    BFormCheckbox,
  },
  mixins: [downloadFileMixin],
  data() {
    return {
      stockFile: null,
      invoiceFiles: [],
      submitting: false,
      errorMessage: null,
      skipSavingVerifiedRecords: false,
    }
  },
  methods: {
    onSubmit() {
      this.submitting = true
      this.errorMessage = ''
      const formData = new FormData()
      if (this.stockFile) {
        formData.append('stock_file', this.stockFile)
      }

      if (this.invoiceFiles.length) {
        for (let x = 0; x < this.invoiceFiles.length; x += 1) {
          formData.append('invoice_files', this.invoiceFiles[x])
        }
      }

      if (this.skipSavingVerifiedRecords) {
        formData.append('skip_saving_verified_records', this.skipSavingVerifiedRecords)
      }

      axios
        .post('/stocks/generate_stock_report/', formData, {
          responseType: 'blob',
        })
        .then(res => {
          const { data } = res
          this.downloadXLSX(data, 'stockReport')

          this.stockFile = null
          this.invoiceFiles = []
        })
        .catch(error => {
          error.response.data.text().then(response => {
            this.errorMessage = JSON.parse(response)?.message || 'Something went wrong'
          })
        }).finally(() => {
          this.submitting = false
        })
    },
  },
}
</script>
